import React from 'react'
import logotype from '../assets/img/svg/logotype.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = () => {
  const year = new Date()
  return (
    <footer className='w-100 bg-light'>
      <div className='container'>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-8">
            <a href='#top' aria-label="Topizdato - Головна"><LazyLoadImage src={logotype} width={140} height={46} alt="Topizdato" /></a>
          </div>
          <div className="col-lg-4 tr">
            <p>ГРОМАДСЬКА ОРГАНІЗАЦІЯ "ТопИздаТо"<br />
            ІПН: 44894768</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-8">
            <p>@{year.getFullYear()} - Topizdato. Усі права захищено</p>
          </div>
          <div className="col-lg-4 tr">
            <a href='/privacy' aria-label="Політика конфіденційності">Політика конфіденційності</a>
          </div>
        </div>
        <div className="s-20"></div>
      </div>
    </footer>
  )
}

export {Footer}
