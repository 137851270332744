import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logotype from '../assets/img/svg/logotype.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Nav = () => {

  const [opened,setOpened] = useState(true)

  return (
    <header className='w-100'>
      <div className='container'>
        <header className="flex jcsb aic h-10 py-5">
          <h1 id="logo">
            <a href='/' aria-label="Topizdato - Головна"><LazyLoadImage src={logotype} width={140} height={46} alt="Topizdato" /></a>
          </h1>
          <div className={opened ? 'burger' : 'burger open'} onClick={() => setOpened(!opened)}>
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu activeMenu' : 'menu'}>
            <NavLink to="/" className='mx-2 px-1' onClick={() => setOpened(!opened)}>Головна</NavLink>
            <NavLink to="about" className='mx-2 px-1' onClick={() => setOpened(!opened)}>Про нас</NavLink>
            <NavLink to="faq" className='mx-2 px-1' onClick={() => setOpened(!opened)}>FAQ</NavLink>
          </div>
        </header>
      </div>
    </header>
  )
}

export {Nav}
